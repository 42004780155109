import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home/index.vue'),
    meta: {
      title: '总览'
    }
  },
  {
    path: '/partybuilding',
    name: 'partybuilding',
    component: () => import('../views/partybuilding/index.vue'),
    meta: {
      title: '智慧党建'
    }
  },
  {
    path: '/partyinfo',
    name: 'partyinfo',
    component: () => import('../views/partyinfo/index.vue'),
    meta: {
      title: '智慧党建详情'
    }
  },
  {
    path: '/fire',
    name: 'fire',
    component: () => import('../views/fire/index.vue'),
    meta: {
      title: '智慧消防'
    }
  },
  {
    path: '/factory',
    name: 'factory',
    component: () => import('../views/factory/index.vue'),
    meta: {
      title: '阳光工厂'
    }
  },
  {
    path: '/energy',
    name: 'energy',
    component: () => import('../views/energy/index.vue'),
    meta: {
      title: '智慧能耗'
    }
  },
  {
    path: '/device',
    name: 'device',
    component: () => import('../views/device/index.vue'),
    meta: {
      title: '智慧运维'
    }
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/video/index.vue'),
    meta: {
      title: '实时监控'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {
      title: '登录'
    }
  },
]
const router = new VueRouter({
  mode: "hash",
  routes
})
router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});
export default router