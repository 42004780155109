import axios from "@/axios";
export default {
  // 安保人员检索（大屏）
  securityPersonnelSearch (params) {
    return axios({
      url: "/equipmentDistributionController/securityPersonnelSearch",
      method: "post",
      params
    })
  },
  //隐患数量
  numberOfFireHazards (params) {
    return axios({
      url: "/equipmentDistributionController/numberOfFireHazards",
      method: "post",
      params
    })
  },
  //隐患列表
  fireHazardList (data) {
    return axios({
      url: "/fireHazard/queryPageDataByParam",
      method: "post",
      data
    })
  },
  numberDevices (params) {
    return axios({
      url: "/equipmentDistributionController/numberDevices",
      method: "post",
      params
    })
  },
  //设备视频列表
  deviceVideoList (params) {
    return axios({
      url: "/equipmentDistributionController/deviceVideoList",
      method: "post",
      params
    })
  },

}