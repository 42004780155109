
import axios from "@/axios";
export default {
  //获取居住用地的区县列表
  getList(params) {
    return axios({
      url: "/bigScreen/vResland/list",
      method: "get",
      params
    })
  },
  getInfo(params) {
    return axios({
      url: '/bigScreen/vResland/getById',
      method: 'get',
      params
    })
  },
  //园林式小区占比分析
  analysisData(params) {
    return axios({
      url: '/bigScreen/summary/complexgreen',
      method: 'get',
      params
    })
  },
  // // 后备名木古树年龄占比分析
  // analysisHistoryData(params) {
  //   return axios({
  //     url: "/bigScreen/summary/ancienttreesJunAge",
  //     method: "get",
  //     params
  //   })
  // },
  // //区域后备名木古树分析
  // analysisDistributedData(params) {
  //   return axios({
  //     url: "/bigScreen/summary/ancienttreesJunDistributed",
  //     method: "get",
  //     params
  //   })
  // }
}