import axios from "@/axios";
export default {
  // 获取区域树
  getAreaList (params) {
    return axios({
      url: "/bigScreen/overview/getDeptList",
      method: "get",
      params
    })
  },
  getVillageList (params) {
    return axios({
      url: "/api/bd/village/getTransformationVillageByYear",
      method: "get",
      params
    })
  }
}