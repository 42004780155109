import axios from "@/axios";
export default {
  // 能耗管理水电能耗
  energyConsumptionManagement (params) {
    return axios({
      url: "/screenController/energyConsumptionManagement",
      method: "post",
      params
    })
  },
  //公司月能耗
  companyMonthlyEnergyConsumption (data) {
    return axios({
      url: "/screenController/companyMonthlyEnergyConsumption",
      method: "post",
      data
    })
  },
  companyMonthlyEnergyConsumption (data) {
    return axios({
      url: "/screenController/companyMonthlyEnergyConsumption",
      method: "post",
      data
    })
  },
  // 楼栋公司能耗（大屏）
  buildingCompanyEnergyConsumption (data) {
    return axios({
      url: "/screenController/buildingEnergyConsumptions",
      method: "post",
      data
    })
  },
  // 各个楼各公司消耗（大屏）
  energyConsumptionByCompany (data) {
    return axios({
      url: "/screenController/companyEnergyConsumptionsByBuildingId",
      method: "post",
      data
    })
  },
  // 年度能耗（大屏）
  annualEnergyConsumption (params) {
    return axios({
      url: "/screenController/annualEnergyConsumption",
      method: "post",
      params
    })
  },
  //设备情况
  queryEquipmentNumber (params) {
    return axios({
      url: "/largedevicemanagement/queryEquipmentNumber",
      method: "get",
      params
    })
  },
}