import axios from "@/axios";
export default {
  getDictItems(params) {
    return axios({
      url: `/sys/api/queryDictItemsByCode`,
      method: 'GET',
      params
    })
  },
  getStaffList() {
    return axios({
      url: '/partyMember/queryAllStaffInfo',
      method: 'get'
    })
  }
}