import axios from "@/axios";
export default {
  // 安保人员检索（大屏）
  numberOfDeviceStates (params) {
    return axios({
      url: "/equipmentDistributionController/numberOfDeviceStates",
      method: "post",
      params
    })
  },
  queryEquipmentNumber (params) {
    return axios({
      url: "/largedevicemanagement/queryEquipmentNumber",
      method: "get",
      params
    })
  },
  getAllEquipmentDisplay (params) {
    return axios({
      url: "/largedevicemanagement/getAllEquipmentDisplay",
      method: "get",
      params
    })
  },
  getEquipmeny(data) {
    return axios({
      url: "/equipmentInfo/queryPageDataByParam",
      method: "post",
      data
    })
  },
  getEquipmenyTask(data) {
    return axios({
      url: "/equipmentMaintenanceTask/queryPageDataByParam",
      method: "post",
      data
    })
  },
  getNumberOfEvents(data){
    return axios({
      url:"artemis/getEventTime",
      method:"get",
      data
    })
  },
  getPreviewURLByRegion(data){
    return axios({
      url:"/artemis/getPreviewURLByRegion",
      method:"post",
      params:data,
    })
  },
  getWarningEvents(data){
    return axios({
      url:"/artemis/getWarning?PageNO="+data.PageNO+'&PageSize='+data.PageSize,
      method:"get",
    })
  },
  getPreviewStream(data){
    return axios({
      url:"/artemis/getCameraPreviewURL",
      method:"post",
      params:data,
    })
  },
  getPlantArea(data){
    return axios({
      url:'/artemis/getRegion',
      method:'get',
      data
    })
  },
  getCameraWithRegion(data){
    return axios({
      url:'/artemis/getCameraWithRegion',
      method:'post',
      params:data,
    })
  },
  getParkCamera(data){
    return axios({
      url:'/artemis/getParkCamera',
      method:'post',
      params:data,
    })
  },
  getFactoryCamera(data){
    return axios({
      url:'/artemis/getFactoryCamera',
      method:'post',
      params:data,
    })
  },
}