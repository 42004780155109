import axios from "@/axios";
export default {
  login(data) {
    return axios({
      url: "/api/sys/login2",
      method: "post",
      data
    })
  }
}

