import axios from "@/axios";
export default {
  //公园配套设施-分页列表查询
  getList(params) {
    return axios({
      url: "/bigScreen/vFacilities/list",
      method: "get",
      params
    })
  },
  getInfo(params) {
    return axios({
      url: '/bigScreen/vFacilities/getById',
      method: 'get',
      params
    })
  },
  getParkList() {
    return axios({
      url: '/bigScreen/facilities/getParkList',
      method: 'get'
    })
  },
  getCountyList(params) {
    return axios({
      url: "/bigScreen/ancientTrees/getCountyList",
      method: "get",
      params
    })
  },
  getTownList(params) {
    return axios({
      url: "/bigScreen/ancientTrees/getTownList",
      method: "get",
      params
    })
  },
   // 绿地现状占比分析
   analysisData(params) {
    return axios({
      url: "/bigScreen/summary/facilities",
      method: "get",
      params
    })
  },
  // 历年绿地现状占比分析
  analysisHistoryData(params) {
    return axios({
      url: "/bigScreen/summary/greenlandHistory",
      method: "get",
      params
    })
  },
  // 区域绿地现状分析
  analysisDistributedData(params) {
    return axios({
      url: "/bigScreen/summary/facilitiesArea",
      method: "get",
      params
    })
  }
  
}