import axios from "@/axios";
export default {
  //决策分析总览
  getOverView(params) {
    return axios({
      url: "/bigScreen/decisionMaking/getOverView",
      method: "get",
      params
    })
  },
  getCityServiceArea(params) {
    return axios({
      url: "/bigScreen/decisionMaking/getCityServiceArea",
      method: "get",
      params
    })
  }
}