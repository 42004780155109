import axios from "@/axios";
export default {
  //获取古树名木的区县列表
  getCountyList(params) {
    return axios({
      url: "/bigScreen/cityPark/getCountyList",
      method: "get",
      params
    })
  },
  getTownList(params) {
    return axios({
      url: "/bigScreen/cityPark/getTownList",
      method: "get",
      params
    })
  },
  // 获取城市公园类别列表
  getTypesList() {
    return axios({
      url: "/bigScreen/cityPark/getTypeList",
      method: "get",
    })
  },
  getList(params) {
    return axios({
      url: "/bigScreen/cityPark/list",
      method: "get",
      params
    })
  },
  getInfo(params) {
    return axios({
      url: '/bigScreen/cityPark/getById',
      method: 'get',
      params
    })
  },
  // 城市公园类别占比分析
  analysisData(params) {
    return axios({
      url: '/bigScreen/summary/cityParkType',
      method: 'get',
      params
    })
  },
  // 历年城市公园分析
  analysisHistoryData(params) {
    return axios({
      url: "/bigScreen/summary/cityParkHistory",
      method: "get",
      params
    })
  },
  //区域城市公园面积分析
  analysisDistributedData(params) {
    return axios({
      url: "/bigScreen/summary/cityParkArea",
      method: "get",
      params
    })
  }
}