import login from './modules/login'
import area from "./modules/area"
import base from "./modules/base"
import home from "./modules/home"
import ancientTrees from "./modules/ancientTrees"
import cityPark from './modules/cityPark'
import ancientTreesJun from './modules/ancientTreesJun'
import greenCoverage from './modules/greenCoverage'
import streetTrees from './modules/streetTrees'
import decisionMaking from './modules/decisionMaking'
import facilities from './modules/facilities'
import greenland from './modules/greenland'
import monitor from './modules/monitor'
import vUnits from './modules/vUnits'
import vMainstreets from './modules/vMainstreets'
import vResland from './modules/vResland'
import resource from './modules/resource'
import plan from "./modules/plan"
import partybuilding from './modules/partybuilding'
import fire from './modules/fire'
import device from './modules/device'
import energy from './modules/energy'

export default {
  login,
  area,
  base,
  home,
  ancientTrees,
  cityPark,
  ancientTreesJun,
  greenCoverage,
  streetTrees,
  decisionMaking,
  facilities,
  greenland,
  monitor,
  vUnits,
  vMainstreets,
  vResland,
  resource,
  plan,
  partybuilding,
  fire,
  device,
  energy
}