
import axios from "@/axios";
export default {
  //获取单位列表
  getList(params) {
    return axios({
      url: "/bigScreen/vUnits/list",
      method: "get",
      params
    })
  },
  getInfo(params) {
    return axios({
      url: '/bigScreen/vUnits/getById',
      method: 'get',
      params
    })
  },
  analysisData(params) {
    return axios({
      url: "/bigScreen/summary/units",
      method: "get",
      params
    })
  },
  analysisHistoryData(params) {
    return axios({
      url: "/bigScreen/summary/greenlandHistory",
      method: "get",
      params
    })
  },
  analysisDistributedData(params) {
    return axios({
      url: "/bigScreen/summary/facilitiesArea",
      method: "get",
      params
    })
  }
}