
import axios from "@/axios";
export default {
  //获取绿化覆盖的区县列表
  getList(params) {
    return axios({
      url: "/bigScreen/vStreettrees/list",
      method: "get",
      params
    })
  },
  getInfo(params) {
    return axios({
      url: '/bigScreen/vStreettrees/getById',
      method: 'get',
      params
    })
  },
  analysisData(params) {
    return axios({
      url: "/bigScreen/summary/streettreesPercentage",
      method: "get",
      params
    })
  },
  analysisHistoryData(params) {
    return axios({
      url: "/bigScreen/summary/streettreesHistory",
      method: "get",
      params
    })
  },
  analysisDistributedData(params) {
    return axios({
      url: "/bigScreen/summary/streettreesArea",
      method: "get",
      params
    })
  },
}