
import axios from "@/axios";
export default {
  getList(params) {
    return axios({
      url: '/bigScreen/vGreencoverage/list',
      method: 'get',
      params
    })
  },
  getInfo(params) {
    return axios({
      url: '/bigScreen/vGreencoverage/getById',
      method: 'get',
      params
    })
  },
  analysisData(params) {
    return axios({
      url: "/bigScreen/summary/greenlandType",
      method: "get",
      params
    })
  },
  analysisHistoryData(params) {
    return axios({
      url: "/bigScreen/summary/greenCoverageHistory",
      method: "get",
      params
    })
  },
  analysisDistributedData(params) {
    return axios({
      url: "/bigScreen/summary/greenCoverageArea",
      method: "get",
      params
    })
  }
  
}