import axios from "@/axios";
export default {
  //党建活动列表
  activityInfo (params) {
    return axios({
      url: "/activityInfo",
      method: "get",
      params
    })
  },
  //党员风采
  partyMember (params) {
    return axios({
      url: "/partyMember",
      method: "get",
      params
    })
  },
  //党建学习
  queryPageDataByParam (data) {
    return axios({
      url: "/learningInfo/queryPageDataByParam",
      method: "post",
      data
    })
  },
  //通知公告
  conditionalPagingQuery (data) {
    return axios({
      url: "/newsAnnouncementController/conditionalPagingQuery",
      method: "post",
      data
    })
  },
  insertNewsAnnouncement(data) {
    return axios({
      url: "/newsAnnouncementController/insertNewsAnnouncement",
      method: "post",
      data
    })
  },
  //党员数量
  partyNumber (data) {
    return axios({
      url: "/partyBuildingTgt/queryPageDataByParam",
      method: "post",
      data
    })
  },
  //轮播图
  getDataManagement(data) {
    return axios({
      url: "/dataManagement/queryPageDataByParam",
      method: "post",
      data
    })
  },


}