import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    year: "2021",
    area: {},
    searchArr: [],
  },
  mutations: {
    SET_YEAR: (state, year) => {
      state.year = year
    },
    SET_AREA: (state,area) => {
      state.area = area
    },
    SET_SEARCHARR: (state ,searchArr) => {
      state.searchArr = searchArr
    },
    REMOVE_SEARCHARR: (state ,str) => {
      let idx = state.searchArr.findIndex(it => it == str)
      state.searchArr.splice(idx,1)
    }
  },
  actions: {
    setYear({ commit }, year) {
      commit('SET_YEAR',year)
    },
    setArea({commit},area) {
      commit('SET_AREA',area)
    },
    setSearchArr({commit},searchArr) {
      commit('SET_SEARCHARR',searchArr)
    },
    removeSearchArr({commit},str) {
      commit('REMOVE_SEARCHARR',str)
    },
  },
  getters: {
    year: state => state.year,
    area: state => state.area,
    searchArr: state => state.searchArr
  },
  modules: {
  },
  plugins: [createPersistedState({
    reducer(val)  {
      return {
        area: val.area,
        searchArr: val.searchArr
      }
    }
  })]
})
