
import axios from "@/axios";
export default {
  getList(params) {
    return axios({
      url: "/bigScreen/monitor/list",
      method: "get",
      params
    })
  },
}