import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
import AMap  from 'vue-amap';
import api from './api'
import ElementUI from 'element-ui';
import VueCryptojs from 'vue-cryptojs';

import 'element-ui/lib/theme-chalk/index.css';
//引入高德地图
Vue.use(AMap);
import './core/use'

//引入dataV
Vue.use(dataV);
Vue.use(ElementUI);
Vue.use(VueCryptojs);

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import 'vue-awesome/icons/plus.js';
import 'vue-awesome/icons/minus.js';

import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Video //引入Video播放器

// 全局注册图标
Vue.component('icon', Icon);

// 适配flex
import '@/common/flexible.js';

// 引入全局css
import './assets/scss/style.scss';

//引入echart
import * as echarts from 'echarts';
import 'echarts-wordcloud';
Vue.prototype.$echarts = echarts
Vue.prototype.$api = api;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
