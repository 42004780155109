import axios from "@/axios";
export default {
  // 获取公园人流实时信息
  getRealTimeTraffic(params) {
    return axios({
      url: "/bigScreen/resource/getRealTimeTraffic",
      method: "get",
      params
    })
  },
  // 获取历史人流
  getHistoricalFlow(params) {
    return axios({
      url: "/bigScreen/resource/getHistoricalFlow",
      method: "get",
      params
    })
  },
  getHistoricalFrames(params) {
    return axios({
      url: "/bigScreen/resource/getHistoricalFrames",
      method: "get",
      params
    })
  },
 
  // 建成区绿地类型占比分析
  getPieChart(params) {
    return axios({
      url: "/bigScreen/resource/getPieChart",
      method: "get",
      params
    })
  },
  // 建成区绿地区域分析
  getAnalysis(params) {
    return axios({
      url: "/bigScreen/resource/getAnalysis",
      method: "get",
      params
    })
  },
  // 年度建成区绿地面积分析
  getBuiltupAreaAnalysis(params) {
    return axios({
      url: "/bigScreen/resource/getBuiltupAreaAnalysis",
      method: "get",
      params
    })
  },
  getGreenResource(params) {
    return axios({
      url: "/bigScreen/resource/getGreenResource",
      method: "get",
      params
    })
  },
  getGreeningIndexDetails(params) {
    return axios({
      url: "/bigScreen/resource/getGreeningIndexDetails",
      method: "get",
      params
    })
  },
  
  // 获取区域列表
  getCountyList() {
    return axios({
      url: "/bigScreen/resource/getCounty",
      method: "get",
    })
  },
  // 获取街道列表
  getTownList(params) {
    return axios({
      url: "/bigScreen/resource/getTown",
      method: "get",
      params
    })
  }
}