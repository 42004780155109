
import axios from "@/axios";
export default {
  getList(params) {
    return axios({
      url: "/bigScreen/vMainstreets/list",
      method: "get",
      params
    })
  },
  getInfo(params) {
    return axios({
      url: '/bigScreen/vMainstreets/getById',
      method: 'get',
      params
    })
  }
}