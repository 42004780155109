import axios from "@/axios";
export default {
  //获取绿化覆盖的区县列表
  getList(params) {
    return axios({
      url: "/bigScreen/vGreenland/list",
      method: "get",
      params
    })
  },
  getInfo(params) {
    return axios({
      url: '/bigScreen/vGreenland/getById',
      method: 'get',
      params
    })
  },
  // 绿地现状占比分析
  analysisData(params) {
    return axios({
      url: "/bigScreen/summary/greenlandType",
      method: "get",
      params
    })
  },
  // 历年绿地现状占比分析
  analysisHistoryData(params) {
    return axios({
      url: "/bigScreen/summary/greenlandHistory",
      method: "get",
      params
    })
  },
  // 区域绿地现状分析
  analysisDistributedData(params) {
    return axios({
      url: "/bigScreen/summary/greenlandArea",
      method: "get",
      params
    })
  }
  
}