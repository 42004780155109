
import axios from "@/axios";
export default {
  //查询所有楼宇
  basicBuilding (params) {
    return axios({
      url: "/basicBuilding",
      method: "get",
      params
    })
  },
  //根据公司id
  queryParkEnterpriseId (companyId) {
    return axios({
      url: `/basicCompany/${companyId}`,
      method: "get",
    })
  },
  //根据楼宇id
  queryParkEnterprise (companyId) {
    return axios({
      url: `/parkProfile/queryParkEnterprise/${companyId}`,
      method: "get",
    })
  },
  getBaseInfo() {
    return axios({
      url: `/basicLargeScreenConfiguration/queryBasicLargeScreenConfiguration`,
      method: "get",
    })
  }
 
 
  
}