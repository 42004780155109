
import axios from "@/axios";
export default {
  // //获取古树名木的区县列表
  // getCountyList(params) {
  //   return axios({
  //     url: "/bigScreen/ancientTrees/getCountyList",
  //     method: "get",
  //     params
  //   })
  // },
  // //获取古树名木的街道列表
  // getTownList(params) {
  //   return axios({
  //     url: "/bigScreen/ancientTrees/getTownList",
  //     method: "get",
  //     params
  //   })
  // },
  // // 获取古树名木地址列表
  // getAddressList(params) {
  //   return axios({
  //     url: "/bigScreen/ancientTrees/getAddressList",
  //     method: "get",
  //     params
  //   })
  // },
  // // 获取古树名木别名列表
  // getAliasList() {
  //   return axios({
  //     url: "/bigScreen/ancientTrees/getAliasList",
  //     method: "get",
  //   })
  // },
  // // 获取古树名木的科名列表
  // getTypesList() {
  //   return axios({
  //     url: "/bigScreen/ancientTrees/getTypesList",
  //     method: "get",
  //   })
  // },
  // // 获取古树名木的保护等级列表
  // getProtectionLevelList(){
  //   return axios({
  //     url: "/bigScreen/ancientTrees/getProtectionLevelList",
  //     method: "get",
  //   })
  // },
  // // 获取古树名木的树龄列表
  // getTreeAgeList() {
  //   return axios({
  //     url: "/bigScreen/ancientTrees/getTreeAgeList",
  //     method: "get",
  //   })
  // },
  // 获取古树名木的列表
  getList(params) {
    return axios({
      url: '/bigScreen/vAncienttrees/list',
      method: 'get',
      params
    })
  },
  getInfo(params) {
    return axios({
      url: '/bigScreen/vAncienttrees/getById',
      method: 'get',
      params
    })
  },
  // 名木古树类别占比分析
  analysisData(params) {
    return axios({
      url: '/bigScreen/summary/ancienttreesType',
      method: 'get',
      params
    })
  },
  // 名木古树年龄占比分析
  analysisHistoryData(params) {
    return axios({
      url: "/bigScreen/summary/ancienttreesAge",
      method: "get",
      params
    })
  },
  //区域名木古树分析
  analysisDistributedData(params) {
    return axios({
      url: "/bigScreen/summary/ancienttreesDistributed",
      method: "get",
      params
    })
  }
  
}